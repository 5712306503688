:root {
    --primary-color: #2c3e50;
    --secondary-color: #3498db;
    --background-color: #f0f3f5;
    --card-background: #ffffff;
    --text-color: #333333;
    --accent-color: #e74c3c;
}

body {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Roboto', Arial, sans-serif;
    line-height: 1.6;
}

.resume-container {
    max-width: 1000px;
    margin: 40px auto;
    background-color: var(--card-background);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.resume-header {
    background-color: var(--primary-color);
    color: #ffffff;
    padding: 40px;
    text-align: center;
}

.resume-header h1 {
    font-size: 36px;
    margin-bottom: 10px;
}

.resume-header h2 {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 20px;
}

.contact-info {
    font-size: 16px;
}

.contact-info a {
    color: var(--secondary-color);
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}

section {
    padding: 30px 40px;
    border-bottom: 1px solid #e0e0e0;
}

h3 {
    color: var(--primary-color);
    font-size: 24px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--secondary-color);
    padding-bottom: 10px;
}

.skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.skill-category h4 {
    color: var(--secondary-color);
    margin-bottom: 10px;
}

.skill-category ul {
    list-style-type: none;
    padding-left: 0;
}

.skill-category li {
    background-color: #f0f0f0;
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 3px;
    font-size: 14px;
}

.job, .project, .education {
    margin-bottom: 25px;
}

.job-header, .project h4 {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 10px;
}

.job-header h4, .project h4 {
    color: var(--secondary-color);
    font-size: 18px;
    margin: 0;
}

.job-header p, .education p {
    color: #666;
    font-size: 14px;
}

ul {
    padding-left: 20px;
}

li {
    margin-bottom: 5px;
}

.achievements-section ul {
    list-style-type: none;
    padding-left: 0;
}

.achievements-section li {
    background-color: #f0f0f0;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border-left: 4px solid var(--accent-color);
}

@media (max-width: 768px) {
    .resume-container {
        margin: 20px;
    }

    .skills-grid {
        grid-template-columns: 1fr;
    }

    .job-header {
        flex-direction: column;
    }
}